<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <right-side-bar
      v-if="openModal"
      button-class="bg-dynamicBackBtn text-white"
      @submit="handleSubmit(functionId ? editFunction : saveFunction)"
      submit="Save"
      @close="cancel"
    >
      <template v-slot:title>
        <p class="text-darkPurple">
          {{ functionId ? "Edit Functional Unit" : "New Functional Unit" }}
        </p>
      </template>
      <div>
        <div style="height:100%" v-if="loadingData">
          <loader size="xxs" :loader-image="false" />
        </div>
        <div v-else>
          <div>
            <div class="grid grid-cols-12 gap-7" style="margin-top:1.125rem">
              <div class="col-span-12 ">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  label="Functional Unit"
                  v-model="functionCredentials.name"
                  :rules="['required']"
                />
              </div>
              <div class="col-span-12 ">
                <c-select
                  placeholder="--Select--"
                  :options="hierarchy"
                  variant="w-full"
                  label="Span of control"
                  v-model="functionCredentials.hierarchyId"
                  :rules="['required']"
                />
              </div>
              <div class="col-span-12 ">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  label="Cost Centre"
                  v-model="functionCredentials.costCenter"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </right-side-bar>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationObserver,
    CText: () => import("@scelloo/cloudenly-ui/src/components/text"),
    CSelect: () => import("@scelloo/cloudenly-ui/src/components/select"),
    RightSideBar: () => import("@/components/RightSideBar")
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    functionId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loadingData: false,
      functionCredentials: {
        name: "",
        costCenter: null,
        reportingTo: null,
        hierarchyId: null
      },
      creatingFunction: false,
      hierarchy: [],
      kpiList: [],
      cursor: "",
      disableBtn: false
    };
  },
  methods: {
    reset() {
      this.functionCredentials = {
        name: "",
        costCenter: null,
        reportingTo: null,
        hierarchyId: null
      };
      this.disableBtn = false;
      this.bgButton = "bg-flame";
      this.cursor = "";
    },
    cancel() {
      if (this.functionId) {
        this.$emit("closeFunctionModal");
      } else {
        this.reset();
        this.$emit("closeFunctionModal");
      }
    },
    saveFunction() {
      this.creatingFunction = true;

      if (this.functionCredentials.reportingTo === "null") {
        this.functionCredentials.reportingTo = null;
      }
      this.functionCredentials.orgId = this.$orgId;
      this.functionCredentials.createdBy = this.$AuthUser.id;

      this.$_createFunction(this.functionCredentials)
        .then(() => {
          this.$emit("closeFunctionModal");
          this.$toasted.success("Function updated sucessfully", {
            duration: 5000
          });
          this.reset();
          this.creatingFunction = false;
        })
        .catch(error => {
          this.creatingFunction = false;
          this.$toasted.error(error, {
            duration: 5000
          });
          this.reset();
          throw new Error(error);
        });
    },
    editFunction() {
      this.creatingFunction = true;
      const editFunction = {
        functionId: this.functionId,
        name: this.functionCredentials.name,
        costCenter: this.functionCredentials.costCenter
          ? this.functionCredentials.costCenter
          : null,
        hierarchyId: this.functionCredentials.hierarchyId,
        kpis: [],
        reportingTo: null
      };
      this.$_editFunction(editFunction)
        .then(() => {
          this.creatingFunction = false;
          this.$emit("closeFunctionModal");
          this.$toasted.success("Function updated successfully", {
            duration: 5000
          });
          this.reset();
        })
        .catch(err => {
          this.creatingFunction = false;
          this.$emit("closeFunctionModal");
          this.$toasted.error(`${err}`, {
            duration: 5000
          });
          this.reset();
        });
    },
    getFunctionRoute() {
      this.loadingData = true;
      if (this.functionId) {
        this.$_getFunctionId(this.functionId).then(result => {
          this.functionCredentials = result.data.orgFunction;
          this.kpiList = result.data.orgFunction.kpis;
        });
        this.functionDetails = true;
        this.kpiDetails = true;
      }
      this.getFunction();
    },
    getFunction() {
      this.$_getHierarchy().then(result => {
        this.hierarchy = result.data.hierarchies;
        this.loadingData = false;
      });
    }
  },
  mounted() {
    this.getFunctionRoute();
  }
};
</script>

<style scoped>
.buttons {
  width: 126px;
  height: 44px;
  border-radius: 5px;
}
</style>
